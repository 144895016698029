<template>
  <div>
    <v-container>
      <v-row justify="center" class="view-row">
        <!-- 준비중 페이지 -->
        <v-col
          cols="12"
          class="prepare"
          v-if="view.pageOrder === 5"
          style="margin-top: 50px"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center a-logo">
              <!-- 임시 준비중 이미지 -->
              <img
                :src="pr.logoAttchId"
                style="max-width: 200px"
                v-if="
                  pr.logoAttchId !== null &&
                  pr.logoAttchId !== undefined &&
                  pr.logoAttchId !== ''
                "
              />
            </v-col>
            <v-col cols="12" class="text-center a-hospital a-hospital-intro">
              <!-- 병원명, 의사명 -->
              안녕하세요. <span>{{ pr.hospNm }} {{ pr.drNm }} 원장</span>입니다.
            </v-col>
            <v-col cols="12" class="text-center a-ing">
              현재 페이지 제작중입니다.
            </v-col>
            <v-col
              cols="12"
              class="a-hospital-num-print text-center"
              v-if="pr.hospTel"
            >
              <div class="a-hospital-num-title a-title">전화번호</div>
              <div class="a-hospital-num-con a-contents">
                <!-- 전화번호 -->
                {{ pr.hospTel }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="a-hospital-locate-title-print text-center"
              v-if="pr.addr"
            >
              <!-- 주소, 세부 주소 -->
              <div class="a-hospital-locate-title a-title">주소</div>
              <div class="a-hospital-locate-con a-contents">
                {{ pr.addr }} {{ pr.addrDtl }}
              </div>
            </v-col>
            <v-col cols="12" v-if="pr.addr" class="hospital-locate">
              <v-row>
                <v-col cols="12">
                  <naver-map
                    v-if="mapLocation.posX !== 1 && loadMap"
                    v-bind:posX="mapLocation.posX"
                    v-bind:posY="mapLocation.posY"
                    v-bind:address="pr.addr + ' ' + pr.addrDtl"
                    v-bind:hospNm="pr.hospNm"
                    v-bind:propsHeight="mapSize.height"
                  ></naver-map>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 자동완성 페이지 -->
        <v-col
          cols="12"
          md="8"
          lg="8"
          xl="8"
          v-if="
            view.pageOrder === 0 ||
            view.pageOrder === 1 ||
            view.pageOrder === 2 ||
            view.pageOrder === 4
          "
          class="doctor-pr"
          id="doctor-pr"
        >
          <v-row justify="center">
            <v-col cols="12">
              <v-row align="center" class="pt-16 doctor-pr-con">
                <v-col cols="6" class="text-right pr-16 hospital-logo">
                  <!-- 로고 -->
                  <v-img
                    :src="pr.logoAttchId"
                    max-height="100%"
                    contain
                    class="hospital-logo-img"
                    v-if="
                      pr.logoAttchId !== null &&
                      pr.logoAttchId !== undefined &&
                      pr.logoAttchId !== ''
                    "
                  >
                  </v-img>
                </v-col>
                <v-col cols="6" class="text-h4 pl-16 hospital-name">
                  <!-- 병원명 -->
                  {{ pr.hospNm }}
                </v-col>
                <v-col cols="12" class="text-center hospital-img">
                  <!-- 병원사진 -->
                  <v-avatar
                    color="indigo lighten-5"
                    rounded="xl"
                    width="95%"
                    height="500"
                    class="pt-2 pb-2 hospital-img-con"
                  >
                    <v-img
                      :src="pr.picAttchId"
                      class="ma-5 hospital-img-view"
                      max-width="100%"
                      cover
                      v-if="
                        pr.picAttchId !== null &&
                        pr.picAttchId !== undefined &&
                        pr.picAttchId !== ''
                      "
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                style="position: relative; bottom: 100px"
                class="doctor-top-info"
              >
                <v-col cols="3" class="text-left pl-16 doctor-img">
                  <!-- 의사 프로필 사진 -->
                  <v-avatar
                    rounded="circle"
                    height="220px"
                    width="220px"
                    style="
                      border: 15px solid white;
                      z-index: 100;
                      background-color: #e0e0e0;
                    "
                    class="dr-profile-img"
                  >
                    <v-img
                      :src="pr.drPicAttchId"
                      max-height="100%"
                      cover
                      v-if="
                        pr.drPicAttchId !== null &&
                        pr.drPicAttchId !== undefined &&
                        pr.drPicAttchId !== ''
                      "
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="9"
                  class="text-left pb-10 doctor-name doctor-name-col"
                  align-self="end"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-h4 font-weight-bold justify-end doctor-name"
                    >
                      <span>{{ pr.drNm }} 원장</span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="hospital-link"
                      v-if="pr.homepage !== null && pr.homepage !== ''"
                    >
                      <span>
                        <img
                          src="@/assets/images/icons/paperclip-solid.svg"
                          style="margin-right: 10px"
                        />
                        <span
                          style="color: #146f83; cursor: pointer"
                          @click="openHomepage"
                        >
                          {{ pr.homepage }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="print-row-up">
                <!-- v-if="keywordList[0] !== undefined" -->
                <v-col cols="12" v-if="keywordList.length > 0">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content class="Medical-subject">
                        <v-list-item-title class="Medical-subject-title title">
                          진료과목
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div></div>
                  <div>
                    <!-- 키워드 리스트 -->
                    <span
                      class="keyword-span"
                      v-for="(k, index) in keywordList"
                      :key="index + 'k'"
                    >
                      <v-chip
                        class="keyword-chip"
                        v-if="k !== '' && k !== null"
                        color="#146f83"
                        outlined
                      >
                        {{ k }}
                      </v-chip>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" v-if="pr.hospIntro" class="hospital-info">
                  <div class="hospital-info-title title">병원소개</div>
                  <div class="hospital-info-con contents">
                    <!-- 병원소개 -->
                    {{ pr.hospIntro }}
                  </div>
                </v-col>
                <v-col cols="12" v-if="pr.etcConts" class="Treatment-time">
                  <div class="Treatment-time-title title">진료시간</div>
                  <!-- 진료시간 -->
                  <div
                    class="Treatment-time-con contents"
                    v-html="computedLine(pr.etcConts)"
                  />
                </v-col>
                <v-col cols="12" v-if="pr.addr" class="hospital-locate">
                  <div class="hospital-locate-title title">주소</div>
                  <!-- 병원 위치 -->
                  <div class="hospital-locate-con contents">
                    {{ pr.addr }} {{ pr.addrDtl }}
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <naver-map
                        v-if="mapLocation.posX !== 1 && loadMap"
                        v-bind:posX="mapLocation.posX"
                        v-bind:posY="mapLocation.posY"
                        v-bind:address="pr.addr + ' ' + pr.addrDtl"
                        v-bind:hospNm="pr.hospNm"
                        v-bind:propsHeight="mapSize.height"
                      ></naver-map>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="pr.hospTel" class="hospital-num">
                  <div class="hospital-num-title title">전화번호</div>
                  <div class="hospital-num-con contents">
                    <!-- 전화번호 -->
                    {{ pr.hospTel }}
                  </div>
                </v-col>
                <v-col cols="6" v-if="pr.hospFaxNo" class="hospitla-fax">
                  <div class="hospitla-fax-title title">팩스</div>
                  <div class="hospital-fax-con contents">
                    <!-- 팩스 -->
                    {{ pr.hospFaxNo }}
                  </div>
                </v-col>
                <!-- <v-col
                  cols="6"
                  v-if="pr.homepage"
                  class="hospital-num hospital-cursor"
                  @click="openHomepage()"
                >
                  <div class="hospital-num-title title">홈페이지</div>
                  <div class="hospital-num-con contents">
                    {{ pr.homepage }}
                  </div>
                </v-col> -->
                <v-col
                  cols="6"
                  v-if="pr.rsvUrl"
                  class="hospitla-fax hospital-cursor"
                  @click="openRevPage()"
                >
                  <div class="hospitla-fax-title title">예약페이지</div>
                  <div class="hospital-fax-con contents">
                    <!-- 예약페이지 -->
                    {{ pr.rsvUrl }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 디자인 페이지 -->
        <v-col cols="12" v-if="view.pageOrder === 3" class="text-center">
          <!-- pr.prAttchId 디자인 주소값을 통해 서버에서 그림파일을 가져옴 -->
          <img
            id="b-type-image"
            class="b-type-image"
            :src="pr.prAttchId"
            v-if="
              pr.prAttchId !== null &&
              pr.prAttchId !== undefined &&
              pr.prAttchId !== ''
            "
            style="max-width: 1000px"
          />
        </v-col>
        <v-col cols="12" class="bottom-btn">
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="12" class="text-center bottom-btn-display">
                  <div style="display: inline-block; margin: 0 15px 30px 0">
                    <v-btn
                      @click="goBackToHome()"
                      color="#ff6600"
                      outlined
                      rounded
                      large
                    >
                      닥터리퍼 홈
                    </v-btn>
                    <div>좋은 의원 검색</div>
                  </div>
                  <div style="display: inline-block; margin: 0 15px 30px 0">
                    <v-btn
                      @click="request()"
                      color="#ff0000"
                      rounded
                      outlined
                      large
                    >
                      전자진료의뢰
                    </v-btn>
                    <div>의사 회원 전용</div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      margin: 0 15px 30px 0;
                      position: relative;
                      top: 24px;
                    "
                    v-if="
                      view.pageOrder === 0 ||
                      view.pageOrder === 1 ||
                      view.pageOrder === 2 ||
                      view.pageOrder === 4 ||
                      view.pageOrder === 5
                    "
                  >
                    <div
                      style="
                        border: 1px solid #0070c0;
                        border-radius: 30px;
                        width: 235px;
                      "
                    >
                      <v-btn
                        @click="openPrintMoadl()"
                        color="#0070c0"
                        outlined
                        rounded
                        large
                        >소개페이지
                      </v-btn>
                      <v-btn
                        @click="requestVisitor()"
                        color="#0070c0"
                        outlined
                        rounded
                        large
                        style="margin-left: 5px"
                        >진료의뢰서
                      </v-btn>
                    </div>
                    <div>로그인 없이 진료의뢰서 출력</div>
                    <div>(2페이지를 각각 출력하셔야 합니다.)</div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      margin: 0 15px 30px 0;
                      position: relative;
                      top: 24px;
                    "
                    v-if="view.pageOrder === 3"
                  >
                    <v-btn
                      @click="requestVisitor()"
                      color="#0070c0"
                      outlined
                      rounded
                      large
                      >진료의뢰서 및 소개페이지 출력
                    </v-btn>
                    <div>로그인 없이 진료의뢰서 출력</div>
                    <div>(2 페이지를 각각 출력하셔야 합니다.)</div>
                  </div>
                  <!-- <div
                    style="
                      display: inline-block;
                      margin: 0 15px 30px 0;
                      position: relative;
                      top: 24px;
                    "
                    v-if="
                      view.pageOrder === 5 
                    "
                  >
                    <v-btn
                      @click="requestVisitor()"
                      color="#0070c0"
                      outlined
                      rounded
                      large
                      >진료의뢰서
                    </v-btn>
                    <div>로그인 없이 진료의뢰서 출력</div>
                    <div>&nbsp;</div>
                  </div> -->
                  <div style="display: inline-block; margin: 0 15px 30px 0">
                    <v-btn
                      @click="closeTab()"
                      color="gray"
                      outlined
                      rounded
                      large
                      style="width: 100px"
                    >
                      닫기
                    </v-btn>
                    <div>&nbsp;</div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="divpop popCenter"
      v-if="
        bookAgrYn === 'Y' ||
        (pr.etcPage[2].value !== null &&
          pr.etcPage[2].value !== undefined &&
          pr.etcPage[2].value !== '' &&
          pr.etcPage[2].value.includes('kakao')) ||
        mobCheck()
      "
    >
      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <v-btn
            fab
            elevation="0"
            x-small
            color="black"
            dark
            @click="popShow = !popShow"
          >
            <v-icon v-show="popShow"> mdi-arrow-right </v-icon>
            <v-icon v-show="!popShow"> mdi-arrow-left </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card elevation="0" color="transparent" flat v-show="popShow">
        <v-card-text class="pt-0 pb-4" v-if="bookAgrYn === 'Y'">
          <v-btn
            fab
            elevation="0"
            color="reform_primary"
            dark
            @click="$router.push('/appointment/' + $route.params.code)"
          >
            <v-icon> mdi-calendar-check-outline </v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text
          class="pt-0 pb-4"
          v-if="
            pr.etcPage[2].value !== null &&
            pr.etcPage[2].value !== undefined &&
            pr.etcPage[2].value !== '' &&
            pr.etcPage[2].value.includes('kakao')
          "
        >
          <v-btn fab elevation="0" color="yellow" @click="goLink()">
            <v-icon> mdi-chat </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="pt-0 pb-4" v-if="mobCheck()">
          <v-btn
            @click="callNumber"
            elevation="0"
            color="reform_orange"
            dark
            fab
          >
            <v-img
              width="10px"
              height="20px"
              contain
              src="@/assets/images/icons/call.svg"
            >
            </v-img>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @printPr="printPrpage"
    />
  </div>
</template>

<script>
import NaverMap from "@/components/NaverMap";
import $ from "jquery";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    NaverMap,
    DefaultPopup,
  },
  data() {
    return {
      popShow: true,
      loadMap: true,
      mapSize: {
        width: 450,
        height: 450,
      },
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      bookAgrYn: "N",
      keywordList: [],
      searchList: [],
      headerOption: {
        navOrder: 1,
      },
      loadBtn: 0,
      popupSet: {},
      pr: {
        logoAttchId: null,
        hospNm: "",
        hospIntro: null,
        conConts: null,
        drPicAttchId: null,
        drNm: null,
        drHist: null,
        doctIntro: null,
        addrDtl: null,
        hospTel: null,
        hospFaxNo: null,
        etcConts: null,
        mapAttchId: null,
        picAttchId: null,
        prAttchId: null,
        rsvUrl: null,
        homepage: null,
        drId: null,
        docAddvTitle: null,
        docAddvConts: null,
        etcPage: [
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
        ],
      },
      view: {
        pageOrder: null || 0,
      },
      isLogin: false,
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);

    if (localStorage.getItem("token") === null) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
    this.getBasicData();
    this.getPromotionData();
    this.getSearchData();
    this.decideBtn();
    let num = document.body.clientWidth + 17;
    // console.log(num);

    if (num > 783 && num < 992) {
      num = 500;
      this.mapSize.width = num + 200;
      this.mapSize.height = num - 50;
    } else if (num > 576 && num <= 783) {
      num = 300;
      this.mapSize.width = num + 180;
      this.mapSize.height = num - 50;
    } else if (num <= 576) {
      num = 300;
      this.mapSize.width = num + 100;
      this.mapSize.height = num - 50;
    } else if (num >= 992) {
      num = 500;
      this.mapSize.width = num + 250;
      this.mapSize.height = num;
    }
    // sdsasds = $(".pr-context").css("height");
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    // console.log("===============");
    // /*console.log(this.$route.name);
    //console.log(this.view.pageOrder);
    // sdsasds = $(".pr-context").css("height");
    // console.log("값확인 ", sdsasds);
  },
  computed: {
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("search", ["GET_SEARCH"]),
    ...mapGetters("request", ["GET_REFER_PDF_NAME"]),
  },
  methods: {
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("util", ["BRING_PDF_PR", "BRING_PDF_URL_PR", "BRING_PDF"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("request", ["SET_REPLY_STATUS"]),
    ...mapActions("search", ["FETCH_SEARCH_WITH_ID"]),
    ...mapActions("request", ["REQUEST_VISITOR_STAUS"]),

    goLink() {
      window.location.href = this.pr.etcPage[2].value;
    },

    callNumber() {
      window.location.href = "tel:" + this.pr.hospTel;
    },

    async getSearchData() {
      //console.log("this.$route.params.code : ", this.$route.params.code);

      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;

      if (!drIdBoo.test(this.$route.params.code)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      const searchParam = {
        drId: this.$route.params.code,
      };
      try {
        await this.FETCH_SEARCH_WITH_ID(searchParam).then(() => {
          // console.log("?", this.GET_SEARCH);

          if (this.GET_SEARCH.etcKeyword1 !== "") {
            if (this.GET_SEARCH.etcKeyword1 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword1);
            }
          }
          if (this.GET_SEARCH.etcKeyword2 !== "") {
            if (this.GET_SEARCH.etcKeyword2 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword2);
            }
          }
          if (this.GET_SEARCH.etcKeyword3 !== "") {
            if (this.GET_SEARCH.etcKeyword3 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword3);
            }
          }
          if (this.GET_SEARCH.etcKeyword4 !== "") {
            if (this.GET_SEARCH.etcKeyword4 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword4);
            }
          }
          if (this.GET_SEARCH.etcKeyword5 !== "") {
            if (this.GET_SEARCH.etcKeyword5 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword5);
            }
          }
          if (this.GET_SEARCH.etcKeyword6 !== "") {
            if (this.GET_SEARCH.etcKeyword6 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword6);
            }
          }

          if (this.GET_SEARCH.operNm1 !== "") {
            if (this.GET_SEARCH.operNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm1);
            }
          }
          if (this.GET_SEARCH.operNm2 !== "") {
            if (this.GET_SEARCH.operNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm2);
            }
          }
          if (this.GET_SEARCH.operNm3 !== "") {
            if (this.GET_SEARCH.operNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm3);
            }
          }

          if (this.GET_SEARCH.diagNm1 !== "") {
            if (this.GET_SEARCH.diagNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm1);
            }
          }
          if (this.GET_SEARCH.diagNm2 !== "") {
            if (this.GET_SEARCH.diagNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm2);
            }
          }
          if (this.GET_SEARCH.diagNm3 !== "") {
            if (this.GET_SEARCH.diagNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm3);
            }
          }

          if (this.GET_SEARCH.equipNm1 !== "") {
            if (this.GET_SEARCH.equipNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm1);
            }
          }
          if (this.GET_SEARCH.equipNm2 !== "") {
            if (this.GET_SEARCH.equipNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm2);
            }
          }
          if (this.GET_SEARCH.equipNm3 !== "") {
            if (this.GET_SEARCH.equipNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm3);
            }
          }

          if (this.GET_SEARCH.symptomNm1 !== "") {
            if (this.GET_SEARCH.symptomNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm1);
            }
          }
          if (this.GET_SEARCH.symptomNm2 !== "") {
            if (this.GET_SEARCH.symptomNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm2);
            }
          }
          if (this.GET_SEARCH.symptomNm3 !== "") {
            if (this.GET_SEARCH.symptomNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm3);
            }
          }
          // console.log("키워드리스트 확인", this.keywordList);
          // console.log("키워드리스트 갯수 확인", this.keywordList.length);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    //소개글이 B타입인 경우 전용 메소드
    print(id) {
      div = document.getElementById(id);

      window.onbeforeprint = this.beforePrint;
      window.onafterprint = this.afterPrint;
      window.print();
    },
    beforePrint() {
      initBody = document.body.innerHTML;
      document.body.innerHTML = div.innerHTML;
    },
    afterPrint() {
      document.body.innerHTML = initBody;
    },
    //(사용하지 않는 메소드)현재 printPrpage로 대체됨
    async popupDisabled() {
      /*this.SET_POPUP(true);
      this.popupSet.title = "의사소개서 작성이 완료되지 않았습니다.";
      this.popupSet.content =
        "현재 의사소개서 작성이 완료되지않아 출력이 불가 합니다.";
      this.popupSet.popType = "custom";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.confirmBtnText = "확인";*/

      //console.log(this.GET_PROMOTION.hospIntroType);

      //소개글 타입이 B타입인 경우
      if (this.GET_PROMOTION.hospIntroType === "3") {
        $(".printExcept").hide();
        $(".pr-context").css({ border: "0px" });
        print("b-type-image");
        return;
      }

      //인쇄 시 하단 아이콘 숨기기
      $(".printExcept").hide();

      //현재 css 값 저장 후 인쇄에 적절한 css 변경

      var printAreaheight = $("#printArea").height();
      $("#printArea").height(220);

      var notificationHeight = $(".notification").height();
      $(".notification").height(1350);

      var contentEmptyWidth = $(".content-empty").width();
      $(".content-empty").width(1200);

      var contentEmptyMarginTop = $(".content-empty").css("marginTop");
      $(".content-empty").css({ marginTop: "500px" });

      var contentEmptyMarginLeft = $(".content-empty").css("marginLeft");
      $(".content-empty").css({ marginLeft: "0px" });

      var printTextFontSize = $(".printText").css("fontSize");
      $(".printText").css({ fontSize: "32px" });

      var printNoticeFontSize = $(".print-notice").css("fontSize");
      $(".print-notice").css({ fontSize: "30px" });

      var printNoticeMinWidth = $(".print-notice").css("minWidth");
      $(".print-notice").css({ minWidth: "1000px" });

      var topIntroducePostion = $(".top-introduce").css("position");
      $(".top-introduce").css({ position: "relative" });

      var topIntroduceTop = $(".top-introduce").css("top");
      $(".top-introduce").css({ top: "50px" });

      var introduceDoctorPosition = $(".introduce-doctor").css("position");
      $(".introduce-doctor").css({ position: "relative" });

      var introduceDoctorTop = $(".introduce-doctor").css("top");
      $(".introduce-doctor").css({ top: "50px" });

      var keywordChipFontSize = $(".keyword-chip").css("fontSize");
      $(".keyword-chip").css({ fontSize: "28px" });

      var keywordChipHeight = $(".keyword-chip").css("height");
      $(".keyword-chip").css({ height: "50px" });

      var keywordWrapMinWidth = $(".keyword-wrap").css("minWidth");
      $(".keyword-wrap").css({ minWidth: "800px" });

      var keywordWrapPosition = $(".keyword-wrap").css("position");
      $(".keyword-wrap").css({ position: "relative" });

      var keywordWrapLeft = $(".keyword-wrap").css("left");
      $(".keyword-wrap").css({ left: "0px" });

      var keywordWrapMarginBottom = $(".keyword-wrap").css("marginBottom");
      $(".keyword-wrap").css({ marginBottom: "0px" });

      var introduceTextMaxWidth = $(".introduce-text").css("minWidth");
      $(".introduce-text").css({ maxWidth: "900px" });

      var diagIntroduceMinWidth = $(".diag-introduce").css("minWidth");
      $(".diag-introduce").css({ minWidth: "300px" });

      var diagIntroduceMarginLeft = $(".diag-introduce").css("marginLeft");
      $(".diag-introduce").css({ marginLeft: "-150px" });

      var diagIntroduceMarginTop = $(".diag-introduce").css("marginTop");
      $(".diag-introduce").css({ marginTop: "40px" });

      var locationIntroduceMinWidth = $(".location-introduce").css("minWidth");
      $(".location-introduce").css({ minWidth: "800px" });

      var locationIntroduceMarginLeft = $(".location-introduce").css(
        "marginLeft"
      );
      $(".location-introduce").css({ marginLeft: "-150px" });

      var phoneTextWidth = $(".phone-text").css("width");
      $(".phone-text").css({ width: "800px" });

      var phoneTextMarginLeft = $(".phone-text").css("marginLeft");
      $(".phone-text").css({ marginLeft: "-150px" });

      var printHomepageWidth = $(".print-homepage").css("width");
      $(".print-homepage").css({ width: "800px" });

      var printHomepageTextMarginLeft = $(".homepage-text").css("marginLeft");
      $(".homepage-text").css({ marginLeft: "-150px" });

      var printHomepageTextMarginTop = $(".homepage-text").css("marginTop");
      $(".homepage-text").css({ marginTop: "0px" });

      var printHomepageAddr = $(".print-homepage").css("fontSize");
      $(".print-homepage-addr").css({ fontSize: "25px" });

      var printHomepagePosition = $(".print-homepage").css("position");
      $(".print-homepage").css({ position: "relative" });

      var printHomepageTop = $(".print-homepage").css("top");
      $(".print-homepage").css({ marginTop: "0px" });

      var mapAreaPosition = $(".map-area").css("position");
      $(".map-area").css({ position: "relative" });

      var prContextPosition = $(".pr-context").css("position");
      $(".pr-context").css({ position: "relative" });
      var prContextTop = $(".pr-context").css("top");
      $(".pr-context").css({ top: "30px" });

      var mapAreaTop = $(".map-area").css("top");
      var locationIntroduceTextHTML = "";
      if ($(".location-introduce-text").html()) {
        locationIntroduceTextHTML = $(".location-introduce-text").html();
      }
      var locationIntroduceTextPosition = $(".location-introduce-text").css(
        "position"
      );
      var locationIntroduceTextTop = $(".location-introduce-text").css("top");
      var printDivMarginTop = $(".print-div").css("marginTop");
      if (
        locationIntroduceTextHTML !== undefined ||
        locationIntroduceTextHTML !== null ||
        locationIntroduceTextHTML !== "" ||
        locationIntroduceTextHTML !== "undefined"
      ) {
        if (locationIntroduceTextHTML.length >= 37) {
          //병원 위치 글자 수 제한에 따라 프린트 css 변경
          $(".diag-introduce").css({ marginTop: "75px" });
          $(".print-div").css({ marginTop: "20px" });
          $(".location-introduce-text").css({ position: "relative" });
          $(".map-area").css({ top: "-20px" });

          if (this.pr.homepage === "") {
            // console.log("홈페이지 없음");
            $(".location-introduce-text").css({ top: "-90px" });
            $(".map-area").css({ top: "-30px" });
          } else {
            $(".location-introduce-text").css({ top: "-40px" });
          }
        } else {
          // console.log("37보다 작음");
          $(".top-introduce").css({ top: "-50px" });
          $(".introduce-doctor").css({ top: "-50px" });
          $(".diag-introduce").css({ marginTop: "-20px" });
          $(".print-div").css({ marginTop: "30px" });
          $(".map-area").css({ left: "-35px" });
          $(".map-area").css({ top: "60px" });
        }
      }

      const html = document.querySelector("html");
      const printContents = document.querySelector(
        ".app-context.util-page"
      ).innerHTML;
      const printDiv = document.createElement("DIV");
      printDiv.className = "print-div";

      //html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      //document.body.style.display = "none";

      var initBody = document.body.innerHTML;
      window.onbeforeprint = function () {
        document.body.innerHTML =
          document.getElementById("printArea").innerHTML;
      };
      window.onafterprint = function () {
        document.body.innerHTML = initBody;
      };
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";

      //인쇄 폼 완료 후 아이콘 보이게 재설정
      $(".printExcept").show();

      //기존 css값으로 되돌리기
      $("#printArea").height(printAreaheight);
      $(".notification").height(notificationHeight);
      $(".content-empty").width(contentEmptyWidth);
      $(".content-empty").css("marginTop", contentEmptyMarginTop);
      $(".printText").css("fontSize", printTextFontSize);
      $(".content-empty").css("marginLeft", contentEmptyMarginLeft);
      $(".print-notice").css("fontSize", printNoticeFontSize);
      $(".print-notice").css("minWidth", printNoticeMinWidth);
      $(".top-introduce").css("position", topIntroducePostion);
      $(".top-introduce").css("top", topIntroduceTop);
      $(".introduce-doctor").css("position", introduceDoctorPosition);
      $(".introduce-doctor").css("top", introduceDoctorTop);
      $(".keyword-chip").css("fontSize", keywordChipFontSize);
      $(".keyword-chip").css("height", keywordChipHeight);
      $(".keyword-wrap").css("minWidth", keywordWrapMinWidth);
      $(".keyword-wrap").css("position", keywordWrapPosition);
      $(".keyword-wrap").css("left", keywordWrapLeft);
      $(".keyword-wrap").css("marginBottom", keywordWrapMarginBottom);
      $(".introduce-text").css("maxWidth", introduceTextMaxWidth);
      $(".diag-introduce").css("minWidth", diagIntroduceMinWidth);
      $(".diag-introduce").css("marginLeft", diagIntroduceMarginLeft);
      $(".diag-introduce").css("marginTop", diagIntroduceMarginTop);
      $(".location-introduce").css("minWidth", locationIntroduceMinWidth);
      $(".location-introduce").css("marginLeft", locationIntroduceMarginLeft);
      $(".phone-text").css("width", phoneTextWidth);
      $(".phone-text").css("marginLeft", phoneTextMarginLeft);
      $(".print-homepage").css("width", printHomepageWidth);
      $(".homepage-text").css("marginLeft", printHomepageTextMarginLeft);
      $(".homepage-text").css("marginTop", printHomepageTextMarginTop);
      $(".print-homepage-addr").css("fontSize", printHomepageAddr);
      $(".print-homepage").css("position", printHomepagePosition);
      $(".print-homepage").css("top", printHomepageTop);
      $(".map-area").css("position", mapAreaPosition);
      $(".map-area").css("top", mapAreaTop);
      $(".location-introduce-text").css(
        "position",
        locationIntroduceTextPosition
      );
      $(".location-introduce-text").css("top", locationIntroduceTextTop);
      $(".print-div").css("marginTop", printDivMarginTop);
      $(".pr-context").css("position", prContextPosition);
      $(".pr-context").css("top", prContextTop);
    },
    computedLine(text) {
      if (text !== null) {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      }
    },
    decideBtn() {
      if (document.referrer) {
        //히스토리 없음 = 새창
        this.loadBtn = 0;
        // console.log("히스토리1");
      } else {
        // console.log("히스토리4");
        if (localStorage.getItem("drId") !== null) {
          // console.log("히스토리2");
          this.loadBtn = 1;
        } else {
          // console.log("히스토리3");
          this.loadBtn = 2;
        }
      }
      //console.log("새창이면 0, 로그인이면 1, 비로그인이면 2 : 결과는? = " + this.loadBtn)
    },
    checkCurrentStatus() {
      if (
        this.GET_PROMOTION.prAttchId !== null &&
        this.GET_PROMOTION.prAttchId !== ""
      ) {
        // console.log("prAttachment catch");
        return false;
      }
      if (
        this.GET_PROMOTION.homepage !== null &&
        this.GET_PROMOTION.homepage !== ""
      ) {
        // console.log("홈페이지에서 걸림");
        return false;
      }
      if (this.GET_PROMOTION.url1 !== null && this.GET_PROMOTION.url1 !== "") {
        // console.log("Url1 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm1 !== null &&
        this.GET_PROMOTION.urlNm1 !== ""
      ) {
        // console.log("Url1 네임");
        return false;
      }
      if (this.GET_PROMOTION.url2 !== null && this.GET_PROMOTION.url2 !== "") {
        // console.log("Url2 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm2 !== null &&
        this.GET_PROMOTION.urlNm2 !== ""
      ) {
        // console.log("Url2 네임");
        return false;
      }
      if (this.GET_PROMOTION.url3 !== null && this.GET_PROMOTION.url3 !== "") {
        // console.log("Url3 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.urlNm3 !== null &&
        this.GET_PROMOTION.urlNm3 !== ""
      ) {
        // console.log("Url3 네임");
        return false;
      }
      if (
        this.GET_PROMOTION.logoAttchId !== null &&
        this.GET_PROMOTION.logoAttchId !== ""
      ) {
        // console.log("로고 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.picAttchId !== null &&
        this.GET_PROMOTION.picAttchId !== ""
      ) {
        // console.log("병원사진 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.drPicAttchId !== null &&
        this.GET_PROMOTION.drPicAttchId !== ""
      ) {
        // console.log("의사사진 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.hospIntro !== null &&
        this.GET_PROMOTION.hospIntro !== ""
      ) {
        // console.log("병원소개 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.doctIntro !== null &&
        this.GET_PROMOTION.doctIntro !== ""
      ) {
        // console.log("의사소개 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.drHist !== null &&
        this.GET_PROMOTION.drHist !== ""
      ) {
        // console.log("의사연혁 걸림");
        return false;
      }
      if (
        this.GET_PROMOTION.conConts !== null &&
        this.GET_PROMOTION.conConts !== ""
      ) {
        // console.log("큰 통");
        return false;
      }
      if (
        this.GET_PROMOTION.etcConts !== null &&
        this.GET_PROMOTION.etcConts !== ""
      ) {
        // console.log("기타 통");
        return false;
      }
      return true;
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async callPdf() {
      if (this.pr.drId === null || this.pr.drId === undefined)
        this.pr.drId = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;

      if (!drIdBoo.test(this.pr.drId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      try {
        await this.BRING_PDF_URL_PR(this.pr.drId).then(() => {
          // console.log("댐", this.GET_PDF_URL);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async fileCall() {
      //console.log(this.GET_PDF_URL.fileName);
      try {
        await this.BRING_PDF_PR(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          // console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          // console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    closeTab() {
      // console.log("clo");
      window.open("", "_self").close();
    },
    goBack() {
      if (localStorage.getItem("drId") !== null) {
        // console.log(window.history.back());
        window.history.back();
        window.history.go(-2);
      } else {
        this.$router.push("/").catch(() => {});
      }
    },
    goBackToHome() {
      this.$router.push("/").catch(() => {});
    },
    phoneFomatter(number) {
      // console.log("정보", number);
      // console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        // console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    request() {
      // console.log("로그인 유무 검증", localStorage.getItem("token"));
      /*if (localStorage.getItem("token") !== null) {
        console.log(this.GET_BASIC);
        console.log(this.GET_PROMOTION);
        const doctorInfo = {
          drId: this.GET_BASIC.drId,
          receive: this.GET_BASIC.hospNm,
          doctor: this.GET_BASIC.drNm,
          referId: null,
          proObj: this.GET_PROMOTION.proObj,//////////////////////////////////
        };
        this.SET_REPLY_STATUS(doctorInfo);
        this.$router.push("/v/request/requestDocument");
      } else {
        this.$router.push("/v/request/requestDocument");
        //this.$refs.appheader.callLoginPop();
      }*/
      const doctorInfo = {
        drId: this.GET_BASIC.drId,
        hospNm: this.GET_BASIC.hospNm,
        drNm: this.GET_BASIC.drNm,
        referId: null,
        proObj: this.GET_PROMOTION.proObj, //////////////////////////////////
        hospTel: this.pr.hospTel,
      };

      //console.log(doctorInfo);

      this.SET_REPLY_STATUS(doctorInfo);
      window.localStorage.setItem("search_doctor", JSON.stringify(doctorInfo));
      //this.$router.push("/v/request/requestDocument");
      //console.log(window.localStorage.getItem("search_doctor"));
      let route = this.$router.resolve({ path: `/v/request/requestDocument` });
      window.open(route.href, "_blank");
      //window.close();
    },
    async requestVisitor() {
      // console.log("============visitor==========");
      const referParam = {
        rcvDrNm: this.GET_BASIC.drNm,
        rcvHospNm: this.GET_BASIC.hospNm,
        rcvParts: this.GET_PROMOTION.proObj,
        hospIntroType: this.GET_PROMOTION.hospIntroType,
        hospTel: this.GET_PROMOTION.hospTel,
        referDt: this.$moment().format("YYYY-MM-DD"),
      };
      const prParam = {
        hospIntro: this.GET_PROMOTION.hospIntro,
        conConts: this.GET_PROMOTION.conConts,
        drPicAttchId: this.GET_PROMOTION.drPicAttchId,
        logoAttchId: this.GET_PROMOTION.logoAttchId,
        drHist: this.GET_PROMOTION.drHist,
        doctIntro: this.GET_PROMOTION.doctIntro,
        addr: this.GET_PROMOTION.addr,
        homepage: this.GET_PROMOTION.homepage,
        etcConts: this.GET_PROMOTION.etcConts,
        mapAttchId: this.GET_PROMOTION.mapAttchId,
        picAttchId: this.GET_PROMOTION.picAttchId,
        prAttchId: this.GET_PROMOTION.prAttchId,
      };

      //console.log("referParam : ", referParam);
      //console.log("prParam : ", prParam);

      //유효성 검사

      // 1. 이름
      if (referParam.rcvDrNm === null || referParam.rcvDrNm === undefined)
        referParam.rcvDrNm = "";

      var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

      if (!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi, ""))) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 2. 병원명
      if (referParam.rcvHospNm === null || referParam.rcvHospNm === undefined)
        referParam.rcvHospNm = "";

      if (
        referParam.rcvHospNm.length < 0 ||
        referParam.rcvHospNm.length > 100
      ) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 3. 진료과

      if (referParam.rcvParts === null || referParam.rcvParts === undefined)
        referParam.rcvParts = "";

      var rcvPartsBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

      if (!rcvPartsBoo.test(referParam.rcvParts)) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 4. 소개글타입
      if (
        referParam.hospIntroType === null ||
        referParam.hospIntroType === undefined
      )
        referParam.hospIntroType = "";

      var hospIntroTypeBoo = /^[0-9]{0,1}$/;

      if (!hospIntroTypeBoo.test(referParam.hospIntroType)) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 5. 전화번호
      if (referParam.hospTel === null || referParam.hospTel === undefined)
        referParam.hospTel = "";

      var hospTelBoo = /^[0-9]{0,14}$/;

      if (!hospTelBoo.test(referParam.hospTel.replace(/-/gi, ""))) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 6. 의뢰일자
      if (referParam.referDt === null || referParam.referDt === undefined)
        referParam.referDt = "";

      var referDtBoo = /^[0-9]{8,8}$/;

      if (!referDtBoo.test(referParam.referDt.replace(/-/gi, ""))) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 7. 병원소개
      if (prParam.hospIntro === null || prParam.hospIntro === undefined)
        prParam.hospIntro = "";

      if (prParam.hospIntro.length < 0 || prParam.hospIntro.length > 3000) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 8. 진료시간
      if (prParam.conConts === null || prParam.conConts === undefined)
        prParam.conConts = "";

      if (prParam.conConts.length < 0 || prParam.conConts.length > 3000) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 9. 의사사진첨부파일번호
      if (prParam.drPicAttchId === null || prParam.drPicAttchId === undefined)
        prParam.drPicAttchId = "";

      if (prParam.drPicAttchId.length < 0 || prParam.drPicAttchId.length > 40) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 10. 로고첨부파일번호
      if (prParam.logoAttchId === null || prParam.logoAttchId === undefined)
        prParam.logoAttchId = "";

      if (prParam.logoAttchId.length < 0 || prParam.logoAttchId.length > 40) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 11. 의사약력
      if (prParam.drHist === null || prParam.drHist === undefined)
        prParam.drHist = "";

      if (prParam.drHist.length < 0 || prParam.drHist.length > 3000) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 12. 의사소개
      if (prParam.doctIntro === null || prParam.doctIntro === undefined)
        prParam.doctIntro = "";

      if (prParam.doctIntro.length < 0 || prParam.doctIntro.length > 3000) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 13. 주소
      if (prParam.addr === null || prParam.addr === undefined)
        prParam.addr = "";

      if (prParam.addr.length < 0 || prParam.addr.length > 200) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 14. 홈페이지 주소
      if (prParam.homepage === null || prParam.homepage === undefined)
        prParam.homepage = "";

      if (prParam.homepage.length < 0 || prParam.homepage.length > 200) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 15. 기타사항
      if (prParam.etcConts === null || prParam.etcConts === undefined)
        prParam.etcConts = "";

      if (prParam.etcConts.length < 0 || prParam.etcConts.length > 3000) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 16. 약도첨부파일번호
      if (prParam.mapAttchId === null || prParam.mapAttchId === undefined)
        prParam.mapAttchId = "";

      if (prParam.mapAttchId.length < 0 || prParam.mapAttchId.length > 40) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 17. 사진첨부파일번호
      if (prParam.picAttchId === null || prParam.picAttchId === undefined)
        prParam.picAttchId = "";

      if (prParam.picAttchId.length < 0 || prParam.picAttchId.length > 40) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 18. 소개첨부파일번호
      if (prParam.prAttchId === null || prParam.prAttchId === undefined)
        prParam.prAttchId = "";

      if (prParam.prAttchId.length < 0 || prParam.prAttchId.length > 40) {
        this.SET_POPUP(true);
        (this.popupSet.title = "진료의뢰서 오류"),
          (this.popupSet.content = "진료의뢰서를 불러올 수 없습니다."),
          (this.popupSet.popType = "warn");
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      const transData = new FormData();
      let transedAssoParam = JSON.stringify(referParam);
      transData.append("referParam", transedAssoParam);
      transedAssoParam = JSON.stringify(prParam);
      transData.append("prParam", transedAssoParam);
      try {
        await this.REQUEST_VISITOR_STAUS(transData).then(async () => {
          // console.log(this.GET_REFER_PDF_NAME);
          await this.BRING_PDF_PR(this.GET_REFER_PDF_NAME.fileName).then(() => {
            // console.log(this.GET_PDF_URL);
            const blob = new Blob([this.GET_PDF_URL], {
              type: "application/pdf",
            });
            const result = URL.createObjectURL(blob);
            // console.log("찾기", result);
            const url = "https://www.drrefer.net/pdf/" + result;
            // console.log("called_Pdf_url", url);

            window.open(result, "_blank");
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getBasicData() {
      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;
      if (!drIdBoo.test(this.$route.params.code)) {
        return;
      }
      try {
        await this.FETCH_BASIC_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            // console.log("찾기 회원정보", this.GET_BASIC);
            if (this.GET_BASIC.hospNm !== null) {
              this.pr.hospNm = this.GET_BASIC.hospNm;
            } else {
              this.pr.hospNm = "";
            }
            if (this.GET_BASIC.drNm !== null) {
              this.pr.drNm = this.GET_BASIC.drNm;
            }
            if (this.GET_BASIC.drId !== null) {
              this.pr.drId = this.GET_BASIC.drId;
            }
            if (this.GET_BASIC.addr !== null) {
              this.pr.addr = this.GET_BASIC.addr;
            }
            if (this.GET_BASIC.addrDtl !== null) {
              this.pr.addrDtl = this.GET_BASIC.addrDtl;
            }
            if (this.GET_BASIC.posX !== null) {
              this.mapLocation.posX = this.GET_BASIC.posX;
            }
            if (this.GET_BASIC.posY !== null) {
              this.mapLocation.posY = this.GET_BASIC.posY;
            }
            if (this.GET_BASIC.bookAgrYn !== null) {
              this.bookAgrYn = this.GET_BASIC.bookAgrYn;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getPromotionData() {
      /**
       *  삭제,변경 => 1
       *  유지,입력 => 0
       *
       */
      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;
      if (!drIdBoo.test(this.$route.params.code)) {
        return;
      }
      try {
        await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            // console.log("찾기 조회정보", this.GET_PROMOTION);
            if (this.GET_PROMOTION.logoAttchId !== null) {
              this.pr.logoAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
            }
            if (this.GET_PROMOTION.hospIntro !== null) {
              this.pr.hospIntro = this.GET_PROMOTION.hospIntro;
            }
            if (this.GET_PROMOTION.conConts !== null) {
              this.pr.conConts = this.GET_PROMOTION.conConts;
            }
            if (this.GET_PROMOTION.drPicAttchId !== null) {
              this.pr.drPicAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
            }
            if (this.GET_PROMOTION.drHist !== null) {
              this.pr.drHist = this.GET_PROMOTION.drHist;
            }
            if (this.GET_PROMOTION.doctIntro !== null) {
              this.pr.doctIntro = this.GET_PROMOTION.doctIntro;
            }
            if (this.GET_PROMOTION.hospTel !== null) {
              this.pr.hospTel = this.phoneFomatter(this.GET_PROMOTION.hospTel);
            }
            if (this.GET_PROMOTION.hospFaxNo !== null) {
              this.pr.hospFaxNo = this.phoneFomatter(
                this.GET_PROMOTION.hospFaxNo
              );
            }
            if (this.GET_PROMOTION.etcConts !== null) {
              this.pr.etcConts = this.GET_PROMOTION.etcConts;
            }
            if (this.GET_PROMOTION.mapAttchId !== null) {
              this.pr.mapAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
            }
            if (this.GET_PROMOTION.picAttchId !== null) {
              this.pr.picAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
            }
            if (this.GET_PROMOTION.prAttchId !== null) {
              this.pr.prAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
            }
            if (this.GET_PROMOTION.rsvUrl !== null) {
              this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
            }
            if (this.GET_PROMOTION.homepage !== null) {
              this.pr.homepage = this.GET_PROMOTION.homepage;
            }
            if (
              this.GET_PROMOTION.url1 !== null &&
              this.GET_PROMOTION.url1 !== ""
            ) {
              this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
            }
            if (
              this.GET_PROMOTION.urlNm1 !== null &&
              this.GET_PROMOTION.urlNm1 !== "" &&
              this.GET_PROMOTION.urlNm1 !== "선택안함"
            ) {
              this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
            }
            if (
              this.GET_PROMOTION.url2 !== null &&
              this.GET_PROMOTION.url2 !== ""
            ) {
              this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
            }
            if (
              this.GET_PROMOTION.urlNm2 !== null &&
              this.GET_PROMOTION.urlNm2 !== "" &&
              this.GET_PROMOTION.urlNm2 !== "선택안함"
            ) {
              this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
            }
            if (
              this.GET_PROMOTION.url3 !== null &&
              this.GET_PROMOTION.url3 !== ""
            ) {
              this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
            }
            if (
              this.GET_PROMOTION.urlNm3 !== null &&
              this.GET_PROMOTION.urlNm3 !== "" &&
              this.GET_PROMOTION.urlNm3 !== "선택안함"
            ) {
              this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
            }
            if (
              this.GET_PROMOTION.docAddvConts !== null &&
              this.GET_PROMOTION.docAddvConts !== ""
            ) {
              this.pr.docAddvConts = this.GET_PROMOTION.docAddvConts;
            }
            if (this.GET_PROMOTION.hospIntroType !== null) {
              this.view.pageOrder = Number(this.GET_PROMOTION.hospIntroType);
            }
            /** 리스트의 정보기입
            if (Object.keys(this.GET_LIST_DOC_INFO).length !== 0) {
              console.log("가져온 닥터리스트의 정보");
              this.getDocInfo.prevUrl = this.GET_LIST_DOC_INFO.prevUrl;
              if (this.GET_LIST_DOC_INFO.proObj !== "일반의") {
                this.getDocInfo.proObj = this.GET_LIST_DOC_INFO.proObj;
              }
            }
            */
            if (this.checkCurrentStatus()) {
              // console.log("아무런정보도 없는 초기회원 진입");
              this.view.pageOrder = 4;
            }

            //console.log("this.view.pageOrder : " + this.view.pageOrder);
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    openPrintMoadl() {
      this.SET_POPUP(true);
      this.popupSet.title = "소개페이지 인쇄 안내";
      this.popupSet.content =
        "인쇄 버튼을 누르면 인쇄가 진행됩니다.\n출력하기 전 옵션에서 '설정 더보기' 또는 '기타 설정'을 클릭하신 후, \n하단 옵션에서 '배경 그래픽' 체크박스를 선택하셔야 소개페이지가 정상적으로 출력됩니다. ";
      this.popupSet.popType = "printPr";
      this.popupSet.cancelBtnText = "인쇄";
      this.popupSet.destination = true;
    },
    printPrpage() {
      this.SET_POPUP(false);
      setTimeout(() => {
        //this.view.pageOrder === 5
        const aViewRowMaxHeight = $(".view-row").css("maxHeight");
        if (this.view.pageOrder === 5) {
          $(".view-row").css({ maxHeight: "1000px" });
        }

        const preparePosition = $(".prepare").css("position");
        const prepareTop = $(".prepare").css("top");
        $(".prepare").css({ position: "relative", top: "-30px" });

        const aHospitalNumPrintPosition = $(".a-hospital-num-print").css(
          "position"
        );
        const aHospitalNumPrintTop = $(".a-hospital-num-print").css("top");
        $(".a-hospital-num-print").css({ position: "relative", top: "-50px" });

        const aHospitalLocateTitlePrintPosition = $(
          ".a-hospital-locate-title-print"
        ).css("position");
        const aHospitalLocateTitlePrintTop = $(
          ".a-hospital-locate-title-print"
        ).css("top");
        $(".a-hospital-locate-title-print").css({
          position: "relative",
          top: "-80px",
        });

        const aHospitalLocatePosition = $(".hospital-locate").css("position");
        const aHospitalLocateTop = $(".hospital-locate").css("top");
        $(".hospital-locate").css({ position: "relative", top: "-60px" });

        const vueNaverMapsPosition = $("#vue-naver-maps").css("position");
        const vueNaverMapsTop = $("#vue-naver-maps").css("top");
        $("#vue-naver-maps").css({ position: "relative", top: "-80px" });

        //////////////////////////else/////////////////
        $(".bottom-btn-display").hide();
        $(".popCenter").hide();

        const hospitalImgMaxHeight = $(".hospital-img").css("maxHeight");
        $(".hospital-img").css({ maxHeight: "200px" });

        const hospitalImgConMaxHeight = $(".hospital-img-con").css("maxHeight");
        $(".hospital-img-con").css({ maxHeight: "200px" });

        const hospitalImgViewMaxHeight =
          $(".hospital-img-view").css("maxHeight");
        $(".hospital-img-view").css({ maxHeight: "200px" });

        const doctorNameColPosition = $(".doctor-name-col").css("position");
        const doctorNameColLeft = $(".doctor-name-col").css("left");
        $(".doctor-name-col").css({ position: "relative", left: "130px" });

        const printRowUpPosition = $(".print-row-up").css("position");
        const printRowUpTop = $(".print-row-up").css("top");
        $(".print-row-up").css({ position: "relative", top: "-120px" });

        const doctorPrPosition = $(".doctor-pr").css("position");
        const doctorPrTop = $(".doctor-pr").css("top");
        $(".doctor-pr").css({ position: "relative", top: "-70px" });

        const hospitalLocatePosition = $(".hospital-locate").css("position");
        const hospitalLocateTop = $(".hospital-locate").css("top");
        $(".hospital-locate").css({ position: "relative", top: "-20px" });

        const hospitalNumPosition = $(".hospital-num").css("position");
        const hospitalNumTop = $(".hospital-num").css("top");
        $(".hospital-num").css({ position: "relative", top: "-20px" });

        const hospitalFaxPosition = $(".hospital-fax").css("position");
        const hospitalFaxTop = $(".hospital-fax").css("top");
        $(".hospitla-fax").css({ position: "relative", top: "-20px" });

        const hospitalCursorPosition = $(".hospital-cursor").css("position");
        const hospitalCursorTop = $(".hospital-cursor").css("top");
        $(".hospital-cursor").css({ position: "relative", top: "-40px" });

        ////////////////////////////////////
        window.print();
        ////////////////////////////////////

        $(".bottom-btn-display").show();
        $(".popCenter").show();

        $(".hospital-img").css("maxHeight", hospitalImgMaxHeight);
        $(".hospital-img-con").css("maxHeight", hospitalImgConMaxHeight);
        $(".hospital-img-view").css("maxHeight", hospitalImgViewMaxHeight);
        $(".doctor-name-col").css("position", doctorNameColPosition);
        $(".doctor-name-col").css("left", doctorNameColLeft);
        $(".print-row-up").css("position", printRowUpPosition);
        $(".print-row-up").css("top", printRowUpTop);
        $(".doctor-pr").css("position", doctorPrPosition);
        $(".doctor-pr").css("top", doctorPrTop);
        $(".hospital-locate").css("position", hospitalLocatePosition);
        $(".hospital-locate").css("top", hospitalLocateTop);
        $(".hospital-num").css("position", hospitalNumPosition);
        $(".hospital-num").css("top", hospitalNumTop);
        $(".hospital-fax").css("position", hospitalFaxPosition);
        $(".hospital-fax").css("top", hospitalFaxTop);
        $(".dhospital-cursor").css("position", hospitalCursorPosition);
        $(".hospital-cursor").css("top", hospitalCursorTop);
        //this.view.pageOrder === 5
        $(".view-row").css("maxHeight", aViewRowMaxHeight);
        $(".prepare").css("position", preparePosition);
        $(".prepare").css("top", prepareTop);
        $(".a-hospital-num-print").css("position", aHospitalNumPrintPosition);
        $(".a-hospital-num-print").css("top", aHospitalNumPrintTop);
        $(".a-hospital-locate-title-print").css(
          "position",
          aHospitalLocateTitlePrintPosition
        );
        $(".a-hospital-locate-title-print").css(
          "top",
          aHospitalLocateTitlePrintTop
        );
        $(".hospital-locate").css("position", aHospitalLocatePosition);
        $(".hospital-locate").css("top", aHospitalLocateTop);
        $("#vue-naver-maps").css("position", vueNaverMapsPosition);
        $("#vue-naver-maps").css("top", vueNaverMapsTop);
      }, 100);
    },
    openHomepage() {
      const homepage =
        this.pr.homepage.indexOf("http://") > -1 ||
        this.pr.homepage.indexOf("https://") > -1
          ? this.pr.homepage
          : this.pr.homepage.indexOf("https://") > -1
          ? "http://" + this.pr.homepage
          : "https://" + this.pr.homepage;
      window.open(homepage);
    },
    openRevPage() {
      const rsvUrl =
        this.pr.rsvUrl.indexOf("http://") > -1 ||
        this.pr.rsvUrl.indexOf("https://") > -1
          ? this.pr.rsvUrl
          : "http://" + this.pr.rsvUrl;

      window.open(rsvUrl);
    },
    mobCheck() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/pr";
@import "@/assets/scss/ViewPrWindow";
</style>
<style lang="scss">
.raw-html-embed {
  margin: 15px 0;
}

figure > img {
  max-width: 100%;
}
.pr-wrap.default
  .pr-context
  .back
  .container
  .hospital
  .info
  .article-wrap
  .article.hsPicrue
  .picture-wrap
  img {
  max-height: 400px !important;
  min-height: 207px !important;
  min-width: 250px !important;
}

.pr-wrap.special .pr-block .block-content.hsPicrue .picture-wrap,
.pr-wrap.special .pr-block .block-content.hsMap .picture-wrap {
  width: 100% !important;
}

.item.prepare {
  .content-empty {
    width: 570px !important;
    margin-top: 30px !important;
    h4.title {
      font-size: 26px !important;
      position: relative;
      top: 10px !important;
      margin-bottom: 10px !important;
    }
    .img-wrap {
      img {
        width: 200px;
      }
    }
    .img_map {
      width: 430px !important;
    }

    .keyword-wrap {
      min-width: 650px !important;
      margin-left: -40px !important;
      position: relative;
      top: 10px !important;
    }
    .keyword-chip {
      margin-top: 8px;
      height: 40px;
      font-size: 18px;
      margin-right: 4px;
    }
    .introduce-text {
      max-width: 600px !important;
    }
    .phone-text {
      margin-top: 20px !important;
    }
    .location-text {
      width: 570px;
      text-align: center;
      padding: 0 0 0 20px;
    }
    .location-text-in {
      font-size: 26px;
    }

    .map-area {
      margin-top: 10px;
      margin-left: -90px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -90px !important;
    }

    .location-introduce {
      margin-top: 10px !important;
    }
  }
}

.pr-context {
  position: relative;
  top: -30px;
}

.mapText {
  font-size: 26px;
}

.pr-wrap-type-c {
  position: relative;
}

@media screen and (min-width: 992px) {
  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 91.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94.5% !important;
  }
}

@media screen and (max-width: 991px) {
  .pr-wrap.special .pr-block .pre-content h3.title {
    text-align: left !important;
  }
  .pr-block.align-center.first {
    .block-content.doctor-profile {
      margin: 10px !important;
      p {
        margin: 0px 10px 0 0 !important;
      }
    }
    .pre-content1 {
      margin: 0px !important;
    }
  }

  .pr-wrap.special .pr-block .block-content,
  .pr-wrap.special .pr-block .pre-content {
    margin: 0 50px 0px 50px !important;
    width: 100% !important;
  }

  .pr-wrap.special
    .pr-block
    .block-content
    .article
    .a-content.input-grid
    .url-list
    p {
    margin: 0px 30px 0 0 !important;
  }
  .pr-wrap.special .pr-block .pre-content h3.title {
    padding: 0 !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 767px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 750px !important;
      }
    }
    .img_map {
      width: 400px !important;
    }
    .map-area {
      margin-left: -65px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -70px !important;
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 90.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 93% !important;
  }
}

@media screen and (max-width: 975px) {
  .ab-btb-wrap-type-d {
    top: 93.5% !important;
  }
  .ab-btb-wrap-type-a {
    top: 85% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94% !important;
  }
}

@media screen and (max-width: 783px) {
  .pr-wrap.special
    .pr-block
    .block-content.doctor-profile
    .d-pircture
    .img-wrap {
    width: 100% !important;
    img {
      margin: auto !important;
    }
  }
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      width: 100% !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }

  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    min-height: 820px !important;
    width: 604px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 580px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 450px !important;
        margin-top: 60px !important;
        h4.title {
          font-size: 20px !important;
          position: relative;
          top: -15px !important;
          margin-bottom: 0px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 330px !important;
        }
        .keyword-wrap {
          min-width: 470px !important;
          margin-left: 0px !important;
          top: -10px !important;
        }
        .keyword-chip {
          font-size: 13px;
          height: 30px;
          padding-right: 20px;
        }
        .introduce-text {
          max-width: 500px !important;
        }
        .location-introduce {
          max-width: 450px !important;
        }

        .location-text {
          width: 450px;
          text-align: center;
          padding: 0 0 0 20px;
        }
        .location-text-in {
          font-size: 20px;
          position: relative;
          top: -28px !important;
          margin-left: -22px !important;
        }
        .location-introduce {
          margin-top: -7px !important;
        }
        .map-area {
          margin-left: -20px;
        }
        .diag-introduce,
        .phone-text,
        .location-introduce,
        .homepage-text {
          margin-left: -23px !important;
        }
      }
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 81% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

@media screen and (max-width: 766px) {
  .ab-btb-wrap-type-b {
    position: relative;
    top: 80% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

@media screen and (max-width: 604px) {
  .pr-wrap.default
    .pr-context
    .back
    .container
    .hospital
    .info
    .article-wrap.default {
    margin: 50px 0 0 0px !important;
    width: 100% !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 550px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 540px !important;
      }
    }

    .introduce-text {
      max-width: 450px !important;
    }
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
}

@media screen and (max-width: 576px) {
  /*
  .ab-btb-wrap {
    top: 790px !important;
  }
  .pr-wrap.default .pr-context .front .container div.title .img-wrap {
    flex: 0 0 100% !important;
    img {
    }
  }
*/
  .ab-btb-wrap-type-e {
    top: 93% !important;
  }

  .pr-wrap.special .pr-block .block-content .article p.type {
    margin: 0px !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 430px !important;
    min-height: 800px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 320px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 350px !important;
        margin-top: 30px !important;
        h4.title {
          font-size: 16px !important;
          position: relative;
          top: 0px !important;
          margin-bottom: -5px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 250px !important;
        }
        .keyword-wrap {
          min-width: 330px !important;
          margin-left: 0px !important;
          top: 10px !important;
        }
        .keyword-chip {
          font-size: 10px;
          height: 26px;
          padding-right: 10px;
        }

        .location-text {
          max-width: 350px;
          padding: 0 0 0 0;
          position: relative;
        }
        .location-text-in {
          font-size: 16px !important;
        }

        .location-introduce {
          max-width: 350px !important;
        }
        .phone-text {
          margin-top: 10px !important;
        }
      }
    }
    .circle-wrap {
      width: 200px !important;
    }
  }
  .mapText {
    font-size: 18px !important;
  }
  .ab-btb-wrap-type-c {
    position: relative;
    top: 92% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}
@media screen and (max-width: 360px) {
  /*
  .ab-btb-wrap {
    top: 660px !important;
  }
*/
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      min-width: 280px !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 300px !important;
    min-height: 730px !important;
    margin: auto;

    .item .img-wrap {
      img {
        max-width: 300px !important;
      }
    }
    .item.prepare {
      .content-empty {
        h4.title {
          font-size: 13px !important;
          position: relative;
          top: 17px !important;
          margin-bottom: -26px !important;
        }
        .title-map {
          margin-top: -10px !important;
        }
        .img_map {
          margin-top: 10px;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .keyword-chip {
          font-size: 10px;
          height: 23px;
        }
      }
    }
    .circle-wrap {
      width: 150px !important;
    }
    .keyword-wrap {
      margin-top: 37px !important;
    }
  }
  .mapText {
    font-size: 13px !important;
  }
}

@media print {
  .print {
    zoom: 98% !important;
  }
}
</style>
<style scoped>
.divpop {
  position: fixed;
  right: 10px;
  z-index: 200;
}

.popCenter {
  top: 1%;
}
</style>
